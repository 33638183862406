<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '配置功能权限' : '配置数据权限'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" label-width="132px" :rules="ruleValidate" class="yt-form yt-dialog-form">
      <el-form-item label="请选择" prop="permissionType" style="margin-bottom: 30px">
        <el-select v-model="formData.permissionType" placeholder="请选择" @change="selectFunction">
          <el-option v-for="item in permissionOptions" :key="item.value" :label="item.name" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="全部权限" prop="permissionAll" style="margin-bottom: 20px">
        <div style="height: 40px;display: flex; align-items: center">
          <el-switch v-model="formData.permissionAll" active-color="#448BFF" />
        </div>
      </el-form-item>
    </el-form>
    <div v-show="!formData.permissionAll" class="config-table">
      <el-tree
        v-if="mode && functionData.length > 0"
        class="yt-dialog-tree"
        ref="config-tree"
        :data="functionData"
        node-key="id"
        :props="functionProps"
        @check-change="handleRelations"
        show-checkbox
        check-strictly
      ></el-tree>
      <template v-else-if="!mode">
        <div style="padding-right: 7px">
          <el-input v-model="keyword" placeholder="通过名称模糊搜索" class="yt-search" @keydown.enter.native="searchData">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchData" />
          </el-input>
        </div>
        <el-table
          ref="dataTable"
          :data="dataList"
          v-loading="dataLoading"
          element-loading-text="加载中"
          class="yt-table "
          @selection-change="selectTable"
        >
          <el-table-column type="selection" width="31" />
          <el-table-column label="名称" prop="dataName" show-overflow-tooltip />
          <el-table-column label="描述" prop="description" show-overflow-tooltip />
        </el-table>
      </template>
    </div>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import sysPermissionApi from '@api/sysPermission'
import dataPermissionApi from '@api/dataPermission'
export default {
  name: 'RolePermissionConfigDialog',
  data() {
    return {
      visible: false,
      mode: true, //true: 功能, false: 数据
      loading: false,
      formData: {
        permissionType: '',
        permissionAll: false,
        permissions: [],
        roleId: 0
      },
      ruleValidate: {
        permissionType: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      permissionOptions: [],
      functionData: [],
      functionProps: {
        label: 'name'
      },
      functionSelection: [],
      dataList: [],
      dataSelection: [],
      dataLoading: false,
      keyword: ''
    }
  },
  methods: {
    open(mode, data) {
      this.mode = mode
      this.formData.roleId = data.roleId
      if (mode) {
        sysPermissionApi.getPermissionType().then(res => {
          this.permissionOptions = res.res
        })
      } else {
        dataPermissionApi.getPermissionType(data.roleId).then(res => {
          this.permissionOptions = res.res.map(item => {
            item.value = item.permissionType
            return item
          })
        })
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    submit() {
      if (this.mode) {
        this.modifyFunctionPermission()
      } else {
        this.modifyDataPermission()
      }
    },
    modifyFunctionPermission() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.formData.permissions = this.$refs['config-tree'].getCheckedKeys()
          sysPermissionApi
            .modifyPermission(this.formData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('权限配置成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    modifyDataPermission() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.formData.permissions = this.dataSelection
          dataPermissionApi
            .modifyPermission(this.formData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('权限配置成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData.permissions = []
      this.dataList = []
      this.functionData = []
      this.loading = false
    },
    selectFunction() {
      if (this.mode) {
        this.searchMenu()
      } else {
        this.searchData()
      }
    },
    searchMenu() {
      sysPermissionApi.getMenuByEndpoint(this.formData.permissionType).then(res => {
        this.functionData = res.res
        sysPermissionApi.getPermissionByRole(this.formData).then(result => {
          this.formData.permissionAll = result.res.permissionAll
          this.$refs['config-tree'].setCheckedKeys(result.res.dataIds, true)
        })
      })
    },
    searchData() {
      let payload = {
        type: this.formData.permissionType,
        searchContent: this.keyword
      }
      this.dataLoading = true
      dataPermissionApi
        .getData(payload)
        .then(res => {
          this.dataList = res.res
          dataPermissionApi.getPermissionByRole(this.formData).then(result => {
            this.formData.permissionAll = result.res.permissionAll
            this.dataList.forEach(item => {
              result.res.dataIds.forEach(id => {
                if (item.dataId === id) {
                  this.$refs.dataTable.toggleRowSelection(item, true)
                }
              })
            })
          })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    handleRelations(data) {
      if (data.hasOwnProperty('relations')) {
        this.functionSelection = this.$refs['config-tree'].getCheckedKeys()
        this.functionSelection.push(...data.relations)
        this.checkTreeNode(this.functionData, data.relations)
        this.$refs['config-tree'].setCheckedKeys(this.functionSelection)
      }
    },
    checkTreeNode(treeNode, relations) {
      for (let item of treeNode) {
        if (relations.includes(item.id)) {
          this.functionSelection.push(item.id)
          if (item.hasOwnProperty('relations')) {
            this.checkTreeNode(this.functionData, item.relations)
          }
        }
        if (item.hasOwnProperty('children')) {
          this.checkTreeNode(item.children, relations)
        }
      }
    },
    selectTable(selection) {
      //表格选择
      this.dataSelection = selection.map(item => {
        return item.dataId
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding-bottom: 12px !important;
}
.config-table {
  width: 423px;
  margin: 30px auto 0 auto;
  .yt-search {
    height: 32px;
    margin-bottom: 7px;
  }
}
.yt-table {
  ::v-deep .el-table__header {
    thead {
      th {
        height: 32px;
        padding: 0;
      }
    }
  }
  ::v-deep .el-table__row {
    height: 32px;
    td {
      padding: 0;
      border-bottom: none;
    }
    &:hover {
      background-color: #f0f0f0;
      border-radius: 4px;
      td {
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
}
</style>
